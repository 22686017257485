import * as React from 'react'
import { Question } from './types'
import { GrayBox, DefaultContainer } from './containers'

export const AskedQuestions: React.FC<{ questions: Question[] }> = ({
  questions,
}) => {
  return (
    <GrayBox>
      <div className={'pb-14 pt-20 text-center'}>
        <DefaultContainer>
          <h2
            className={'mb-4 text-4xl font-medium'}
            children={'Preguntas frecuentes'}
          />
          <p className={'mb-14'}>
            Algunas de las preguntas que se le realizan a nuestro personal a
            diario.
          </p>

          <div className={'grid grid-cols-1	md:grid-cols-2 gap-16'}>
            {questions.map((asked, index) => (
              <div key={index} className={'text-left'}>
                <h3 className={'text-xl font-medium mb-2'}>{asked.question}</h3>
                <p>{asked.answer}</p>
              </div>
            ))}
          </div>
        </DefaultContainer>
      </div>
    </GrayBox>
  )
}
