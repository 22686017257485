import * as React from 'react'
import { PageProps } from 'gatsby'
import { SectionOne, SectionTwo, Contact } from '../components/page'
import { ProductsSection } from '../components/product-section'
import { Layout } from '../components/default-layout'
import { SEO, LDJSON, buildCatalog, buildFAQ } from '../components/seo'
import { MainImage, useImages } from '../components/image'
import { SpecificLogo } from '../components/logo'
import { CatalogComponent } from '../components/catalog'
import { LocationSplitList } from '../components/locations'
import { getCatalog, getAskedQuestions } from '../data/locksmith-catalog'
import { Slider } from '../components/slider'
import { AskedQuestions } from '../components/questions'

export const Head = () => {
  const images = useImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])
  const questions = React.useMemo(() => getAskedQuestions(), [])

  return (
    <SEO
      article={false}
      title={'Cerrajería a domicilio'}
      description={
        'Cerrajero a domicilio las 24 horas del día en Bogotá. Cerrajería residencial, empresarial y automotriz, apertura de cerraduras, etc.'
      }
    >
      <LDJSON
        data={[
          buildFAQ(questions),
          buildCatalog('Locksmith', {
            name: 'Domicilios 24/7',
            categories: catalog.categories,
          }),
        ]}
      />
    </SEO>
  )
}

const Locksmith: React.FC<PageProps> = () => {
  const images = useImages()
  const catalog = React.useMemo(() => getCatalog(images), [images])
  const questions = React.useMemo(() => getAskedQuestions(), [])

  return (
    <Layout
      theme={'yellow'}
      visible={{ locksmith: true }}
      logo={<SpecificLogo>Cerrajería</SpecificLogo>}
    >
      <Slider
        id={'inicio'}
        overlay={0.55}
        business={'Cerrajero profesional'}
        wallpaper={'lockSmithSlider'}
        pageTitle={'Servicio de Cerrajería 24/7'}
        subTitle={'Cerrajero las 24 horas del día'}
        pageDescription={
          <>
            Servicio de <strong>cerrajero a domicilio</strong> las 24 horas del
            día en Bogotá. <strong>Cerrajería automotriz</strong>, empresarial y
            residencial, apertura de toda clase de cerraduras, cambio de
            guardas, cajas fuertes, puertas de seguridad, duplicado de llaves,
            brazos hidráulicos, etc.
          </>
        }
        goTo={'#servicios'}
      />

      <ProductsSection
        id={'servicios'}
        pageTitle={'Cerrajeros expertos'}
        business={'Cerrajería a Domicilio'}
        businessType={'Locksmith'}
        pageSubTitle={
          <>
            Estos son algunos los servicios de{' '}
            <strong>cerrajería en Bogotá</strong> con los cuales contamos.
          </>
        }
        products={<CatalogComponent light catalog={catalog} />}
      />

      <AskedQuestions questions={questions} />

      <SectionTwo
        id={'cobertura'}
        image={
          <MainImage
            alt={'Desplazamiento en motocicleta'}
            reference={'fastDelivery'}
            className={'rounded'}
          />
        }
        pageTitle={'¿Necesitas un cerrajero urgente?'}
        description={
          <>
            <p>
              Disponemos de varias sedes distribuidas en toda la ciudad.
              Contamos con un servicio de <strong>cerrajería en Bogotá</strong>{' '}
              que puede solicitar ahora mismo, nuestro profesional dispone de
              transporte motorizado para desplazarse al lugar que desee. Si
              necesita un <strong>cerrajero urgente</strong> no se preocupe,
              estos son algunos de los lugares donde contamos con cobertura casi
              inmediata:
            </p>
            <LocationSplitList columns={2} />
          </>
        }
      />

      <SectionOne
        id={'nosotros'}
        pageTitle={'Garantías y calidad del servicio'}
        image={<MainImage alt={'Cerrajero'} reference={'man'} />}
        description={
          <>
            <p>
              Contamos con una basta trayectoria en{' '}
              <strong>cerrajería automotriz</strong> de más de <b>8 años</b>,
              nuestro clientes siempre han estado satisfechos, debido a la
              calidad del servicio que prestan nuestros{' '}
              <strong>cerrajeros</strong>.
            </p>
            <p>
              En caso de que no esté satisfecho con la calidad de servicio de
              alguno de nuestros cerrajeros no dude en contactarse con nosotros,
              somos los más interesados en mejorar nuestro servicio y mejorar en
              todos los aspectos.
            </p>
            <p>
              También tenemos soporte las 24 horas del día, 7 días a la semana,
              en caso de que tenga un problema, queja, reclamo o sugerencia no
              se preocupe, también estaremos ahí para escucharlo.
            </p>
          </>
        }
      />

      <Contact
        id={'contacto'}
        business={'Locksmith'}
        businessName={'Cerrajería'}
        priceRange={'$20.000 - $400.000'}
      />
    </Layout>
  )
}

export default Locksmith
